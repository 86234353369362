<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          <span>
            Perfis
          </span>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            outlined
            @click="modal = true, clearModal()"
          >
            <v-icon class="mr-1">
              {{ icons.mdiPlaylistPlus }}
            </v-icon>
            Novo
          </v-btn>
        </v-card-title>

        <v-row>
          <v-col
            cols="12"
          >
            <v-data-table
              :loading="loadTable"
              loading-text="Carregando dados... Por favor, aguarde"
              :headers="headers"
              :items="items"
              :page.sync="pageOptions.page"
              :items-per-page="pageOptions.itemsPerPage"
              hide-default-footer
            >
              <template #[`item.edit`]="{ item }">
                <v-icon
                  color="info"
                  @click="getProfileInfo(item)"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </template>

              <template #[`item.delete`]="{ item }">
                <v-icon
                  color="error"
                  @click="deleteProfile(item)"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </template>
            </v-data-table>
            <v-card-text class="pt-2 pb-1">
              <v-row>
                <v-col
                  lg="12"
                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-pagination
                    v-model="pageOptions.page"
                    total-visible="6"
                    :length="pageOptions.pageCount"
                    @input="nextPageGetProfiles()"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>

        <v-dialog
          v-model="modal"
          width="400"
        >
          <v-card>
            <v-card-title
              color="error"
            >
              <h3>Perfil</h3>
            </v-card-title>
            <v-card>
              <v-card-text>
                <form action="">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="profile.profile"
                        dense
                        outlined
                        label="Nome"
                        variant="underlined"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col
                      v-if="edit"
                      cols="auto"
                    >
                      <v-btn
                        color="success"
                        prepend-icon="mdi-content-save"
                        @click="saveChanges"
                      >
                        salvar
                      </v-btn>
                    </v-col>
                    <v-col
                      v-else
                      cols="auto"
                    >
                      <v-btn
                        color="info"
                        prepend-icon="mdi-plus"
                        @click="createProfile"
                      >
                        cadastrar
                      </v-btn>
                    </v-col>
                  </v-row>
                </form>
              </v-card-text>
            </v-card>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline, import/no-cycle
import axiosIns from '@/plugins/axios'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data() {
    return {
      modal: false,
      edit: false,
      profileId: '',
      loadTable: false,
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'PERFIL', value: 'profile' },
        { text: 'EDITAR', value: 'edit', align: 'center' },
        { text: 'DELETAR', value: 'delete', align: 'center' },
      ],
      items: [],
      infoBase: {},
      profile: {},
    }
  },
  created() {
    this.getProfiles()
  },
  methods: {
    clearModal() {
      this.edit = false
      this.profile = {
        profile: '',
      }
    },

    closeModal() {
      this.modal = false
      this.getProfiles()
    },

    async saveChanges() {
      await axiosIns
        .put(`/api/v1/records/profile/update/${this.profileId}`, this.profile)
        .then(async () => {
          this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Perfil atualizado!',
            text: 'Dados do perfil atualizados!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar dados do perfil!',
            showConfirmButton: false,
            text: error,
          })
        })
    },
    async createProfile() {
      await axiosIns
        .post('/api/v1/records/profile/store', this.profile)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Perfil cadastrado!',
            text: 'Novo perfil cadastrado!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao cadastrar perfil!',
            showConfirmButton: false,
            text: error,
          })
        })
    },
    async getProfileInfo(item) {
      this.modal = true
      this.profileId = item.id
      this.edit = true
      await axiosIns.get(`/api/v1/records/profile/edit/${item.id}`).then(res => {
        this.infoBase = res.data.data
        this.profile = res.data.data
      })
    },
    async deleteProfile(item) {
      await this.$swal({
        icon: 'warning',
        title: 'Deseja excluir o Perfil?',
        text: 'A operação é irreversivel, tem certeza?',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não, cancelar!',
      })
        .then(async result => {
          if (result.isConfirmed) {
            await axiosIns.delete(`/api/v1/records/profile/destroy/${item.id}`).then(async () => {
              this.closeModal()
              await this.$swal({
                icon: 'success',
                title: 'Perfil excluido!',
                text: 'O perfil foi excluido!',
                showConfirmButton: false,
                timer: 2000,
              })
            })
          } else {
            await this.$swal({
              icon: 'error',
              title: 'Cancelado!',
              text: 'Operação cancelada',
              showConfirmButton: false,
              timer: 2000,
            })
          }
        })
        .catch(async error => {
          this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao excluir perfil!',
            text: error,
          })
        })
    },
    async getProfiles() {
      this.loadTable = true
      await axiosIns.get('/api/v1/records/profile/index').then(res => {
        this.items = res.data.data
        this.pageOptions.itemsPerPage = res.data.meta.per_page
        this.pageOptions.pageCount = res.data.meta.last_page
        this.loadTable = false
      })
    },
    async nextPageGetProfiles() {
      this.loadTable = true
      await axiosIns.get(`/api/v1/records/profile/index?page=${this.pageOptions.page}`).then(res => {
        this.items = res.data.data
        this.pageOptions.itemsPerPage = res.data.meta.per_page
        this.pageOptions.pageCount = res.data.meta.last_page
        this.loadTable = false
      })
    },
  },
}
</script>

<style>
</style>
